<template>
    <div>
        <div class="vcard" :id="`popover-flag-item-${flag.flag_id}-${uiuuid}`">
            <span><b>{{flag.value}}</b></span>
            <b-button class="close-button material-icons" variant="danger" v-if="$store.getters.checkUIPC({path: ['form', 'actions', 'removeFlag'] })" @click="performDeleteBehavior">delete_forever</b-button>
        </div>
        <b-popover :target="`popover-flag-item-${flag.flag_id}-${uiuuid}`" triggers="hover" placement="top">
            <template #title>Flag Details</template>
            <span><b>Notes:</b> {{flag.note}}</span><br>
            <span><b>Flag Added On:</b> {{parseTimestampToDate(flag.added_on)}}</span><br>
            <span><b>Added By:</b> {{flag.added_by_display_name}}</span><br v-if="showParentLink">
            <b-link v-if="showParentLink" @click="showFormForItem(flag)" class="card-link">Applied To {{parseParentType(flag.parent_is)}}, Click To View {{parseParentType(flag.parent_is)}}</b-link>
        </b-popover>
        <b-modal :id="'flag-delete-confirmation-' + flag.flag_id" title="Confirm Flag Removal" hide-footer>
                <b>Are you sure you wish to delete this flag?</b><br><br>
                <b-form-checkbox
                    :id="'flag-delete-confirmation-' + flag.flag_id + '-chk-dont-ask-again'"
                    v-model="dontAskSelected"
                    name="dont-ask-again"
                    :value="true"
                    :unchecked-value="false"
                >
                    Don't ask for confirmation again this session
                </b-form-checkbox><br>
                <b-button-toolbar justify>
                    <b-button variant="warning" @click="hideModal">Cancel</b-button>
                    <b-button variant="danger" @click="processDeleteModal">Delete Flag</b-button>
                </b-button-toolbar>
            </b-modal>
    </div>
    <!-- <b-card :title="flag.value" :sub-title="flag.description">
        <b-card-text></b-card-text>
        <b-card-text v-if="showParentLink"><b-link @click="showFormForItem(flag)" class="card-link">Applied To {{parseParentType(flag.parent_is)}}, Click To View {{parseParentType(flag.parent_is)}}</b-link></b-card-text>
        <b-card-text>{{parseTimestampToDate(flag.added_on)}}</b-card-text>
        <b-card-text>{{flag.added_by_display_name}}</b-card-text>
        <b-button v-if="!showConfirmDelete" @click="deleteStageOne" variant="danger">Remove</b-button>
        <b-button v-if="showConfirmDelete" @click="cancelDelete" variant="warning">Cancel</b-button>
        <b-button v-if="showConfirmDelete" @click="deleteSelf" variant="danger">Yes, Delete</b-button>
    </b-card> -->
    
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
const butils = require('../../libs/basicUtils.js');

export default {
    name: 'flagcard',
    props:{
        flag: {
            type: Object,
            default: () => { return {} }
        },
        showParentLink:{
            type: Boolean,
            default: () => { return false}
        },
        onRemoveFlag:{
            type: Function
        }
    },
    data(){
        return{
            dontAskSelected: false,
            uiuuid: null
        }
    },
    methods: {
        showModal(){
            this.$bvModal.show('flag-delete-confirmation-' + this.flag.flag_id);
        },
        hideModal(){
            this.$bvModal.hide('flag-delete-confirmation-' + this.flag.flag_id);
        },
        parseParentType(input){
            switch (input) {
                case 'customers':
                    return "Customer";
                case 'sites':
                    return "Sites";
                case 'connections':
                    return "Connection";
                case 'devices':
                    return "Device";
                default:
                    return "";
            }
        },
        parseTimestampToDateTime(input){
            return butils.formatters.timestampToDateTime(input, true, false, '\r\n ');
        },
        parseTimestampToDate(input){
            return butils.formatters.timestampToDateTime(input);
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        showFormForItem(item){
            var thisFormType = item.parent_is;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + item.parent })
            }
        },
        performDeleteBehavior(){
            var skipConfirm = this.$store.getters.flagDeleteWithoutConfirm;
            if(skipConfirm){
                this.deleteSelf();
            }else{
                this.showModal();
            }
        },
        deleteSelf(){
            instance.delete(process.env.VUE_APP_API_BASE_URL + '/flags/' + this.flag.id + '.json')
            .then((response)=>{
                this.onRemoveFlag(this.flag.id);
            })
            .catch((err)=>{
                console.log(err);
                this.makeToast("Failed To Delete Flag", "API request failed", "danger");
            })
        },
        processDeleteModal(){
            if(this.dontAskSelected){
                this.$store.commit('flagDeleteDontAsk');
            }
            this.hideModal();
            this.deleteSelf();
        }
    },
    computed: {
    },
    watch:{
    },
    mounted(){
        
    },
    created(){
    },
    beforeDestroy(){
        
    },
    beforeMount (){
        this.uiuuid = uuidv4();
        // console.log(this.wo);
    },
}
</script>

<style scoped>
.close-button {
    position: absolute;
    top: 0px;
    right: 15px;
    padding-left: 2px;
    padding-right:2px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.vcard{
    padding: 0px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin: 0px;
}
</style>

