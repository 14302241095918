<template>
    <div v-b-hover="hoverOverlay" :style="`height: ${displaySize}px; width: ${displaySize}px;`">
        <b-overlay 
                :show="overlay"
                @click="startOpenModal(id)"
            >
                <template v-slot:overlay>
                    <div class="text-center">
                        <span class='material-icons very-large-icon'>zoom_in</span><br>
                    </div>
                </template>
                <div
                    overlay 
                    @click="startOpenModal(id)"
                >
                    <b-img-lazy v-bind:src="baseFileURL + '/' + viewSize + '_' + id" fluid ></b-img-lazy>
                </div>
                
            </b-overlay>
        
        <b-modal :ref="'gallery-modal-' + id" id="modal-xl" size="xl" :ok-only="true" title="View Photo Details">
            <b-row>
                <b-col md="12" lg="6">
                    <b-img-lazy v-bind:src="photoModal.source" fluid ></b-img-lazy>
                </b-col>
                <b-col md="12" lg="6">
                    <div v-if="photoModal.gps.latitude != ''">
                        <span>
                            <strong><em>Photo Location Information:</em></strong>
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Accuracy:</em>
                            {{photoModal.gps.dop}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Latitude:</em>
                            {{photoModal.gps.latitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Longitude:</em>
                            {{photoModal.gps.longitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Altitude:</em>
                            {{photoModal.gps.altitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Timestamp:</em>
                            {{photoModal.gps.timestamp}}
                        </span>
                        <br />
                        <b-button class="tab-in" variant="info" size="sm" :href="photoModal.gmapViewLink" target="_blank">View GPS Location On Google Maps</b-button>
                        <hr>
                    </div>
                <span>
                    <strong><em>Available Actions:</em></strong>
                </span>
                <br />
                <b-row class="mr-3 mb-1">
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in" 
                            :href="photoModal.sizes.original.link" 
                            download 
                            target="_blank"
                            block
                        >
                            View Original Size
                        </b-button>
                    </b-col>
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in ml-3" 
                            @click="downloadImg(photoModal.sizes.original)"
                            block
                        >
                            Download Original Size
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="mr-3 mb-1">
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in" 
                            v-if="photoModal.sizes.large != null"
                            :href="photoModal.sizes.large.link" 
                            download 
                            target="_blank"
                            block
                        >
                            View Large Size
                        </b-button>
                    </b-col>
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in ml-3" 
                            @click="downloadImg(photoModal.sizes.large)"
                            block
                        >
                            Download Large Size
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="mr-3 mb-1">
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in" 
                            v-if="photoModal.sizes.small != null"
                            :href="photoModal.sizes.small.link" 
                            download 
                            target="_blank"
                            block
                        >
                            View Small Size
                        </b-button>
                    </b-col>
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in ml-3" 
                            @click="downloadImg(photoModal.sizes.small)"
                            block
                        >
                            Download Small Size
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="mr-3 mb-1">
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in" 
                            v-if="photoModal.sizes.thumbnail != null"
                            :href="photoModal.sizes.thumbnail.link" 
                            download 
                            target="_blank"
                            block
                        >
                            View Thumbnail Size
                        </b-button>
                    </b-col>
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in ml-3" 
                            @click="downloadImg(photoModal.sizes.thumbnail)"
                            block
                        >
                            Download Thumbnail Size
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="mr-3">
                    <b-col cols="6">
                        <b-button 
                            size="sm" 
                            class="btn-small-separation tab-in" 
                            v-if="photoModal.hasExif"
                            @click="toggleShowExifData()"
                            block
                        >
                            {{(photoModal.showExif) ? "Hide Detailed Photo Info (EXIF)" : "View Detailed Photo Info (EXIF)"}}
                        </b-button>
                    </b-col>
                </b-row>
                </b-col>
            </b-row>
            <b-row v-if="photoModal.showExif">
                <b-col cols="12">
                    <hr>
                    <b-card sub-title="Server Data:">
                        <span class="tab-in">
                            <em>Original File Name:</em> {{photoModal.uploadFileName}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded On:</em> {{photoModal.uploadDate}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded By User ID:</em> {{photoModal.uploadedBy}}
                        </span>
                    </b-card>
                    <hr>
                    <b-card sub-title="Exif Data">
                        <div class="tab-in" v-for="(itm, index) in photoModal.exifKeys" :key="index">
                            <span>
                                <em>{{itm}}: </em> {{photoModal.exif[itm]}}
                            </span>
                            <br />
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <template v-slot:modal-footer="{ ok }">
                <small class="text-muted" v-if="photoModal.gps.timestamp != ''">Photo Taken On: {{photoModal.gps.timestamp}}</small>
                <small class="text-muted" v-else-if="photoModal.uploadDate != null">Photo Uploaded To System On: {{photoModal.uploadDate}}</small>
                <b-button @click="ok()" variant="primary">Done</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const _ = require('underscore');
var fileDownload = require('js-file-download');

export default {
    name: 'imageviewercomponent',
    props: {
        value:{
            type: String,
            default: () =>{ return null; }
        },
        imgID: {
            type: String,
            default: () =>{ return null; }
        },
        viewSize: {
            type: String,
            default: () =>{ return 'thumb'; }
        },
        displaySize: {
            type: Number,
            default: () =>{ return 256}
        }
    },
    components: {
    },
    data(){
        return{  
            id: null,
            photoMeta: null,
            overlay: false,
            photoModal:{
                showExif: false,
                uploadDate: null,
                uploadFileName: null,
                uploadedBy: null,
                source: '',
                sourceIs: 'original',
                sizes: {
                    thumbnail: null,
                    small: null,
                    large: null,
                    original: {
                        MIMEType: null,
                        link: null,
                        size: null
                    }
                },
                gps:{
                    altitude: '',
                    dop: '',
                    latitude: '',
                    longitude: '',
                    timestamp: ''
                },
                hasExif: false,
                exifKeys: [],
                exif:{},
                gmapViewLink: null
            },
            // baseFileURL: "http://localhost:3007"
            baseFileURL: process.env.VUE_APP_FILE_API_BASE_URL,
        }
    },
    methods: {
        downloadImg(obj){
            var jwt = this.$store.getters.getJWT;
            const pdfInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': obj.MIMEType
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            //Get User Token and Fetch The Values Required For This Page
            pdfInstance.get(obj.link)
            .then(async (response) => {
                console.log(response);
                var contentDisp = response.headers["content-disposition"];
                var split = obj.link.split('/');
                
                fileDownload(response.data, split[split.length - 1]);
            })
            .catch((error) => {
                if(error.response.status == 404){
                    console.log("Unable To Fetch Photo For Download");
                }
                console.log(error);
            });
        },
        hoverOverlay(isHovering){
            this.overlay = isHovering;
        },
        startOpenModal(photoID){
            console.log(`Clicked on: ${photoID}`)
            var metaProm = this.fetchPhotoMeta(photoID);
            metaProm.then((response) => {
                this.photoMeta = response.data.result;
                this.enlargePhoto(this.photoMeta);
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchPhotoMeta(photoID){
            return instance.get(this.baseFileURL + '/meta/' + photoID)
        },
        enlargePhoto(img){
            this.photoModal.uploadDate = new Date(img.uploadInfo.uploadedOn).toString();
            this.photoModal.uploadFileName = img.uploadInfo.uploadFileName;
            this.photoModal.uploadedBy = img.uploadInfo.uploadedBy;
            if(_.has(img, 'exif')){
                this.photoModal.hasExif = true;
                this.photoModal.exif = img.exif;
                this.photoModal.exifKeys = _.keys(img.exif);
            }
            if(_.has(img, 'gps')){
                this.photoModal.gps = {
                    altitude: (_.has(img.gps, 'alt') ? img.gps.alt : ''),
                    dop: (_.has(img.gps, 'dop') ? img.gps.dop : ''),
                    latitude: (_.has(img.gps, 'lat') ? img.gps.lat : ''),
                    longitude: (_.has(img.gps, 'lng') ? img.gps.lng : ''),
                    timestamp: (_.has(img.gps, 'timestamp') ? new Date(img.gps.timestamp).toString() : ''),
                }
            }
            this.photoModal.sizes = {
                thumbnail: (_.has(img, 'thumb') ? img.thumb : null),
                small: (_.has(img, 'small') ? img.small : null),
                large: (_.has(img, 'large') ? img.large : null),
                original: (_.has(img, 'original') ? img.original : null),
            };

            if(this.photoModal.sizes.large != null){
                this.photoModal.source = this.photoModal.sizes.large.link;
                this.photoModal.sourceIs = 'large';
            }else{
                this.photoModal.source = this.photoModal.sizes.original.link;
                this.photoModal.sourceIs = 'original';
            }
            var map = "https://www.google.com/maps/search/?api=1&query=" +
                this.photoModal.gps.latitude + "," + this.photoModal.gps.longitude;
            this.photoModal.gmapViewLink = map;
            this.$refs['gallery-modal-' + this.id].show();
        },
        toggleShowExifData(){
            this.photoModal.showExif = !this.photoModal.showExif;
        },
    },
    beforeCreate(){
    },
    beforeMount (){
        if(this.imgID != null){
            this.id = this.imgID;
        }else if(this.value != null){
            this.id = this.value;
        }else{
            this.id = null;
        }
        
    },
}
</script>

<style scoped>
.very-large-icon{
    font-size: 3em;
}
</style>