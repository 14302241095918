<template>
    <div v-if="fetchComplete">
        <b-row align-h="center">
            <b-col cols="12">
                <h2><b>Task Comment</b></h2>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col>
                <h4><b>Technician: </b>{{comment.tech_first_name + " " + comment.tech_last_name}}</h4>
                <h4><b>Event Date: </b>{{parseTimestampToDateTime(comment.event_tstamp)}}</h4>
                <h4><b>Comment: </b>{{comment.comment}}</h4>
            </b-col>
        </b-row>
        <hr>
        <b-row v-if="comment.photos.length > 0">
            <b-col>
                <h4><b>Images</b></h4>
            </b-col>
        </b-row>
        <b-row v-if="comment.photos.length > 0">
            <b-col xs="12" sm="6" md="4" lg="3" xl="2" v-for="(photoID, index) in comment.photos" :key="'photo_' + index">
                <hover-image-with-modal :imgID="photoID"></hover-image-with-modal>
            </b-col>
        </b-row> 
        <b-row class="mt-2">
            <!-- Spacer -->
        </b-row>
        <b-card header="Flags" header-class="larger-header">
            <b-card-body>
                <b-row>
                    <b-col xs="12" med="6" lg="4" xl="3" v-for="(flag, index) in comment.flag_values" :key="index">
                        <flag-card :flag="flag" :showParentLink="true"></flag-card>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');

const butils = require('../../libs/basicUtils.js')

import Image from '@/components/subcomponents/Image.vue';
import FlagCard from '@/components/subcomponents/FlagCard.vue';

export default {
    name: 'viewtaskcomment',
    props: {
        eventID: {
            type: String
        },
    },
    components: {
        'hover-image-with-modal': Image,
        'flag-card': FlagCard
    },
    data(){
        return{
            apiEndpoint: 'events/comment/',
            comment: {},
            fetchComplete: false,
        }
    },
    methods: {
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        parseParentType(input){
            switch (input) {
                case 'customers':
                    return "Customer";
                case 'sites':
                    return "Sites";
                case 'connections':
                    return "Connection";
                case 'devices':
                    return "Device";
                default:
                    return "";
            }
        },
        parseTimestampToDateTime(input){
            return butils.formatters.timestampToDateTime(input, true, false, '\r\n ');
        },
        parseTimestampToDate(input){
            return butils.formatters.timestampToDateTime(input);
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: 3000,
                appendToast: true
            })
        },
        showFormForItem(item){
            var thisFormType = item.parent_is;
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + item.parent })
            }
        }
    },
    watch: {
    },
    beforeMount(){
        instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.apiEndpoint + this.eventID + '.json')
            .then(async (response) => {
                this.comment = {};
                this.comment = response.data.result.records[0];
                if(this.comment.photos == null){
                   this.comment.photos = []; 
                }
                this.fetchComplete = true;
            })
            .catch((error) => {
                console.log(error);
                this.fetchComplete = true;
            });
    },
}
</script>

<style scoped>
.larger-header{
    font-size: 1.5em;
}
</style>
